import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../../components/Layout';
import PageHeader from '../../components/PageHeader';
import CompanyQuote from '../../components/CompanyQuote';
import PageBackgroundImage from '../../components/PageBackgroundImage';
import { getSeoConfig } from '../../constants/defaultSeoProps';

import s from './PrivacyPolicy.module.scss';

const propTypes = {
  companyQuote: PropTypes.shape({
    title: PropTypes.string,
    quote: PropTypes.string,
    footerDescription: PropTypes.shape({}).isRequired,
    icons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

const seoParams = {
  ogImagePath: '/og-images/home.png',
  ogTitle: 'Privacy Policy | Tech Holding',
  ogPath: '/privacy-policy',
};

const updatedSeo = getSeoConfig(seoParams);

const PrivacyPolicy = ({ companyQuote }) => (
  <Layout
    currentPage="/privacy-policy"
    seo={updatedSeo}
    title={updatedSeo.openGraph.title}
    footerDescription={companyQuote.footerDescription}
  >
    <PageBackgroundImage theme="static">
      <PageHeader
        title="Privacy Policy"
        description="By accessing or using the Tech Holding Co. website, you agree to the terms of this Online Privacy Policy, as outlined below. If you do not agree to these terms, please do not access or use this site."
      />
      <div className={s.container}>
        <div className={s.personalInfo}>
          <h3>Collection of Personal Information</h3>
          <p>
            {`When you engage in certain activities on this site, you may be asked to provide certain information about yourself by filling out and submitting an online form. It is completely optional for you to engage in these activities. If you elect to engage in these activities, however, you may be required to provide personal information, such as your name, mailing address, e-mail address, and other personal identifying information.`}
          </p>
          <p>
            {`When you submit personal information, you understand and agree that Tech Holding Co. may transfer, store, and process your information in any of the countries in which Tech Holding Co. maintain offices, including without limitation, the United States.`}
          </p>
          <p>
            {`Tech Holding Co. collects this information in order to record and support your participation in the activities you select. Tech Holding Co. also uses information that you provide as part of our effort to keep you informed about product upgrades, special offers, and other Tech Holding Co. products and services. Tech Holding Co. will never sell or share your information with other companies.`}
          </p>
        </div>
      </div>
      <CompanyQuote companyQuote={companyQuote} />
    </PageBackgroundImage>
  </Layout>
);

PrivacyPolicy.propTypes = propTypes;
export default PrivacyPolicy;
