import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '../Button';
import s from './PageHeader.module.scss';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  buttonDetails: PropTypes.shape({
    onClick: PropTypes.func,
    label: PropTypes.string,
    link: PropTypes.string,
  }),
};

const defaultProps = {
  children: null,
  className: '',
  description: '',
  title: '',
  buttonDetails: {},
};

const PageHeader = ({ title, description, className, buttonDetails, children }) => (
  <div className={s.container} data-aos="fade-up" data-test="pageHeader">
    <div className={classnames(s.root, className)}>
      {!children ? (
        <>
          <h2 data-test="pageHeaderTitle">{title}</h2>
          <p className={s.description} data-test="pageHeaderDescription">
            {description}
          </p>

          {buttonDetails?.label && (
            <div className={s.button} data-aos="fade-up" data-aos-delay="400">
              <Button
                onClick={buttonDetails?.onClick}
                // href={buttonDetails?.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {buttonDetails.label}
              </Button>
            </div>
          )}
        </>
      ) : (
        children
      )}
    </div>
  </div>
);

PageHeader.defaultProps = defaultProps;
PageHeader.propTypes = propTypes;
export default PageHeader;
