import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import s from './CompanyQuote.module.scss';

const propTypes = {
  companyQuote: PropTypes.shape({
    title: PropTypes.string,
    quote: PropTypes.string,
    icons: PropTypes.array.isRequired,
  }).isRequired,
};

const CompanyQuote = ({ companyQuote }) => (
  <div className={s.layeredComponent} data-test="companyQuote">
    <div className={s.bottomLayer}>
      <div className={s.darkBackground} />
    </div>
    <div className={s.topLayer}>
      <div className={s.copyContainer}>
        <div className={s.awsLogos}>
          <h3 className={s.quote} data-test="cqTitle">
            {companyQuote.title}
          </h3>
          <p className={s.quote} data-test="cqQuote">
            {companyQuote.quote}
          </p>
          <div className={s.quote}>
            {companyQuote.icons &&
              companyQuote.icons.length > 0 &&
              companyQuote.icons.map((icon) => (
                <div key={icon.fluid.src} data-test="cqIcons">
                  <Img fluid={icon.fluid} className={s.emoji} />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

CompanyQuote.propTypes = propTypes;
export default CompanyQuote;
