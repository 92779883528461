import React from 'react';
import PropTypes from 'prop-types';
import s from './PageBackgroundImage.module.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.oneOf(['static', 'animated']).isRequired,
};

const PageBackgroundImage = ({ theme, children }) => {
  let imageClass;
  if (theme === 'static') {
    imageClass = s.staticOne;
  }
  if (theme === 'animated') {
    imageClass = s.animatedOne;
  }

  return <div className={imageClass}>{children}</div>;
};

PageBackgroundImage.propTypes = propTypes;
export default PageBackgroundImage;
