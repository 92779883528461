const { GATSBY_BASE_URL } = process.env;

const defaultSeoProps = {
  seo: {
    lang: 'en',
    description: `Tech Holding is an international full-service technology consulting agency in Los Angeles, California.`,
    openGraph: {
      image: '/og-image.png',
      title: 'Tech Holding',
      description: `Tech Holding is an international full-service technology consulting agency in Los Angeles, California.`,
      pathname: '/',
    },
  },
};

export const getSeoConfig = ({
  description = defaultSeoProps.seo.description,
  ogImagePath = defaultSeoProps.seo.openGraph.image,
  ogTitle = defaultSeoProps.seo.openGraph.title,
  ogDesc = defaultSeoProps.seo.openGraph.description,
  ogPath = defaultSeoProps.seo.openGraph.pathname,
} = {}) => ({
  lang: 'en',
  description,
  openGraph: {
    image: `${GATSBY_BASE_URL}${ogImagePath}`,
    title: ogTitle,
    description: ogDesc,
    pathname: ogPath,
  },
});

export default { defaultSeoProps, GATSBY_BASE_URL };
