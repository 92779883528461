import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import PrivacyPolicy from '../containers/PrivacyPolicy';

const propTypes = {
  data: PropTypes.shape({
    contentfulCompanyQuote: PropTypes.shape({
      title: PropTypes.string,
      quote: PropTypes.string,
      icons: PropTypes.arrayOf({
        fluid: PropTypes.shape({}),
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

const PrivacyPolicyPage = ({ data }) => (
  <PrivacyPolicy companyQuote={data.contentfulCompanyQuote} />
);

PrivacyPolicyPage.propTypes = propTypes;
export default PrivacyPolicyPage;

export const query = graphql`
  query {
    contentfulCompanyQuote(label: { eq: "servicesAws" }) {
      title
      quote
      footerDescription {
        json
      }
      icons {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`;
